<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <q-form ref="editForm">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!-- 기본정보 -->
            <c-card title="LBLBASEINFO" class="cardClassDetailForm">
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <!-- 기계분류 -->
                  <c-select
                    required
                    type="edit"
                    codeGroupCd="HHM_TYPE_CD"
                    itemText="codeName"
                    itemValue="code"
                    name="machineryClassification"
                    label="LBL0002500"
                    v-model="equipInfo.machineryClassification"
                  ></c-select>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <!-- 기계명 -->
                  <c-text
                    required
                    :disabled="disabled"
                    :editable="editable"
                    label="LBL0002507"
                    name="machineryName"
                    v-model="equipInfo.machineryName">
                  </c-text>
                </div>
                <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-text
                    :afterIcon="[
                      { name: 'search', click: true, callbackName: 'add'},
                      { name: 'close', click: true, callbackName: 'remove'}
                    ]"
                    :editable="editable"
                    label="LBL0002527"
                    :disabled="disabled"
                    :close="false"
                    name="equipmentNo"
                    @add="add"
                    @remove="remove"
                    v-model="equipInfo.equipmentNo"
                  >
                  </c-text>
                </div> -->
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-text
                    :editable="editable"
                    :disabled="disabled"
                    label="LBL0002517"
                    name="itemNo"
                    v-model="equipInfo.itemNo">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <!-- 단위공정 -->
                  <c-process
                    :editable="editable"
                    label="LBL0002509"
                    multiple="multiple"
                    name="subProcessCd"
                    v-model="equipInfo.subProcessCd">
                  </c-process>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <!-- 담당부서 -->
                  <c-dept type="edit" required :disabled="disabled" label="LBL0002501" name="departmentDeptCd" v-model="equipInfo.departmentDeptCd" />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-plant
                    required
                    :editable="editable"
                    type="edit"
                    name="plantCd"
                    v-model="equipInfo.plantCd" />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <!-- 신청인 -->
                  <c-field
                    :editable="editable"
                    label="LBL0002528"
                    type="user" 
                    name="departmentUserId"
                    v-model="equipInfo.departmentUserId">
                  </c-field>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <!-- 사용여부 -->
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    label="LBLUSEFLAG"
                    name="useFlag"
                    v-model="equipInfo.useFlag">
                  </c-checkbox>
                </div>
              </template>
            </c-card>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!-- 설치상태 정보 -->
            <c-card title="LBL0002529" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <!-- 저장 -->
                  <c-btn
                    v-if="editable"
                    :url="saveUrl"
                    :isSubmit="isSave"
                    :param="equipInfo"
                    :mappingType="saveType"
                    label="LBLSAVE"
                    icon="save"
                    @beforeAction="saveInfo"
                    @btnCallback="saveCallback" 
                  />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <!-- 설치일 -->
                  <c-datepicker
                    type="date"
                    :editable="editable"
                    label="LBL0002530"
                    name="emplacementMonth"
                    v-model="equipInfo.emplacementMonth">
                  </c-datepicker>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <!-- 철거일 -->
                  <c-datepicker
                    :editable="editable"
                    type="date"
                    label="LBL0002531"
                    name="demolitionMonth"
                    v-model="equipInfo.demolitionMonth"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <!-- 제조사 -->
                  <c-text
                    :editable="editable"
                    label="LBL0002532"
                    name="manufacturer"
                    v-model="equipInfo.manufacturer">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <!-- 제조일 -->
                  <c-datepicker
                    :editable="editable"
                    type="date"
                    label="LBL0002533"
                    name="manufactDate"
                    v-model="equipInfo.manufactDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <!-- 용량 -->
                  <c-text
                    suffix="ton"
                    type="number"
                    :editable="editable"
                    label="LBL0002534"
                    name="volume"
                    v-model="equipInfo.volume">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <!-- 설치장소 -->
                  <c-text
                    :editable="editable"
                    label="LBL0002535"
                    name="emplacementPlace"
                    v-model="equipInfo.emplacementPlace">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <!-- EWR NO. -->
                  <c-text
                    :editable="editable"
                    label="LBL0002536"
                    name="ewrNo"
                    v-model="equipInfo.ewrNo">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <!-- 발생가능재해형태 -->
                  <c-text
                    :editable="editable"
                    label="LBL0002537"
                    name="possibleDisaster"
                    v-model="equipInfo.possibleDisaster">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <!-- 방호장치 -->
                  <c-text
                    :editable="editable"
                    label="LBL0002538"
                    name="protectDevice"
                    v-model="equipInfo.protectDevice">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <!-- 비고 -->
                  <c-text
                    :editable="editable"
                    label="LBLREMARK"
                    name="remark"
                    v-model="equipInfo.remark">
                  </c-text>
                </div>
              </template>
            </c-card>
          </div>
        </div>
        <!-- 안전검사정보 -->
        <c-card class="cardClassDetailForm" title="LBL0002539">
          <template slot="card-detail">
            <div class="col-3">
              <!-- 등급 -->
              <c-select
                type="edit"
                codeGroupCd="HAZARD_GRADE_CD"
                itemText="codeName"
                itemValue="code"
                name="gradeCd"
                label="LBL0002502"
                v-model="equipInfo.gradeCd"
              ></c-select>
            </div>
            <div class="col-3">
              <!-- 검사상태 여부 -->
              <c-select
                required
                :comboItems="targetCdItems"
                type="edit"
                itemText="codeName"
                itemValue="code"
                :editable="editable"
                label="LBL0002504"
                name="inspectionSubjectFlag"
                v-model="equipInfo.inspectionSubjectFlag"
                @datachange="datachange">
              </c-select>
            </div>
            <div class="col-3">
              <!-- 검사종류 -->
              <c-select
                type="edit"
                :disabled="!(equipInfo.inspectionSubjectFlag === 'Y')"
                codeGroupCd="MDM_CHECK_KIND_CD"
                itemText="codeName"
                itemValue="code"
                :editable="editable"
                label="LBL0002510"
                name="inspectionKindCd"
                v-model="equipInfo.inspectionKindCd">
              </c-select>
            </div>
            <!-- HAZARD_CYCLE_CD -->
            <div class="col-3">
              <!-- 검사주기 -->
              <c-select
                :editable="editable"
                :disabled="!(equipInfo.inspectionSubjectFlag === 'Y')"
                type="edit"
                codeGroupCd="MDM_EQUIP_CYCLE_CD"
                itemText="codeName"
                itemValue="code"
                label="LBL0002511"
                name="inspectionCycle"
                v-model="equipInfo.inspectionCycle">
              </c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 검사증교부일 -->
              <c-datepicker
                :editable="editable"
                label="LBL0002514"
                name="inspCertificateIssuanceDate"
                v-model="equipInfo.inspCertificateIssuanceDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 유효기간 -->
              <c-datepicker
                :editable="editable"
                :range="true"
                label="LBL0002540"
                name="validPeriod"
                v-model="validPeriod">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 합격번호 -->
              <c-text
                :editable="editable"
                label="LBL0002541"
                name="passNumber"
                v-model="equipInfo.passNumber">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 관련 법규 -->
              <c-multi-select
                :editable="editable"
                :isArray="false"
                codeGroupCd="REGULATION_BILL_API_CD"
                itemText="codeName"
                itemValue="code"
                label="LBL0002542"
                name="relatedLaws"
                maxValues="3"
                v-model="equipInfo.relatedLaws">
              </c-multi-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!-- 설계근거(정격하중) -->
              <c-text
                :editable="editable"
                label="LBL0002543"
                name="esignBasisRatedLoad"
                v-model="equipInfo.esignBasisRatedLoad">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!-- 설계근거(종류) -->
              <c-text
                :editable="editable"
                label="LBL0002544"
                name="esignBasisKind"
                v-model="equipInfo.esignBasisKind">
              </c-text>
            </div>
            <div class="col-12">
              <!-- 검사면제사유 -->
              <c-textarea
                :editable="editable"
                label="LBL0002545"
                :rows="3"
                name="reasonExemptionInspection"
                v-model="equipInfo.reasonExemptionInspection">
              </c-textarea>
            </div>
          </template>
        </c-card>
        <div class="col-12">
          <!-- 유해위험기계기구 인증서 -->
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="LBL0002546">
          </c-upload>
        </div>
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from "quasar";
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-equipment-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
      }),
    },
    attachInfo: {
      type: Object,
      default() {
        return {
          isSubmit: '',
          taskClassCd: 'HAZARD_EQUIPMENT_INFO',
          taskKey: '',
        }
      }
    },
    // validPeriod: {
    //   type: Array,
    //   default: () => []
    // },
    equipInfo: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
        equipmentTypeCd: '', 
        equipmentCd: '', 
        equipmentTypeName: '',
        equipmentName: '',
        plantCd: null,
        machineryClassification: null,
        itemNo: '',
        inspectionKindCd: null,
        manufacturer: '',
        manufactDate: '',
        emplacementPlace: '',
        emplacementMonth: '',
        demolitionMonth: '',
        passNumber: '',
        ewrNo: '',
        amount: '',
        esignBasisRatedLoad: '',
        esignBasisKind: '',
        inspCertificateIssuanceDate: '',
        lastInspectionDate: '',
        inspectionSubjectFlag: null,
        inspectionCycle: null,
        nextInspectionDate: '',
        reasonExemptionInspection: '',
        machineryName: '',
        gradeCd: null,
        departmentDeptCd: '',
        departmentUserId: '',
        relatedLaws: '',
        remark: '',
        regUserId: '',
        chgUserId: '',
        subProcessCd: '',
        useFlag: 'Y',
        protectDevice: '',
        volume: '',
        possibleDisaster: '',
        validStartDate: '',
        validEndDate: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      targetCdItems: [
        { code: "Y", codeName: "대상" },
        { code: "N", codeName: "비대상" },
      ], // 대상여부
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      saveType: 'POST',
      isSave: false,
      checkUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      updateMode: false,
      getUrl: '',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.equipInfo.equipmentCd !== '';
    },
    validPeriod: {
      get() {
        if (this.equipInfo.validStartDate && this.equipInfo.validEndDate) {
          return [this.equipInfo.validStartDate, this.equipInfo.validEndDate]
        } else {
          return this.newDate;
        }
      },
      set(newDate) {
        if (newDate && newDate.length > 0) {
          this.validPeriod[0] = newDate[0]
          this.validPeriod[1] = newDate[1]
        } 
        this.newDate = newDate;
      }
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.saveUrl = transactionConfig.sop.hhm.hazard.check.insert.url;
      this.checkUrl = selectConfig.sop.hhm.hazard.check.check.url;
      this.insertUrl = transactionConfig.sop.hhm.hazard.check.insert.url;
      this.updateUrl = transactionConfig.sop.hhm.hazard.check.update.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.check.delete.url;
    },
    saveInfo() {
      if (this.popupParam.hhmHazardousMachineryId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
            this.$http.url = this.checkUrl
            this.$http.type = 'GET';
            this.$http.param = this.equipInfo;
            this.$http.request((_result) => {
              if (_result.data > 0) {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: 'MSG0000752', // 사업장 내 이미 존재하는 기계관리번호입니다.
                  type: 'warning', // success / info / warning / error
                });
                return;
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',
                  message: 'MSGSAVE', // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    if (this.validPeriod && this.validPeriod.length > 0) {
                      this.equipInfo.validStartDate = this.validPeriod[0];
                      this.equipInfo.validEndDate = this.validPeriod[1];
                    }
                    this.equipInfo.regUserId = this.$store.getters.user.userId;
                    this.equipInfo.chgUserId = this.$store.getters.user.userId;
                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                  });
                }
            },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = 'LBL0002547'; // 설비 검색
      this.popupOptions.param = {
        type: 'single',
        hazardousFlag: 'Y'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.length > 0) {
          this.equipInfo.equipmentCd = data[0].equipmentCd; 
          this.equipInfo.equipmentNo = data[0].equipmentNo; 
          this.equipInfo.machineryName = data[0].equipmentName;
          this.equipInfo.amount = data[0].amount;

          this.equipInfo.subProcessCd = data[0].subProcessCd;
          this.equipInfo.departmentDeptCd = data[0].managementDepts; // 담당부서
          // 설비 주기와 관련법규 연결하면 주석 풀어도 됨
          // this.equipInfo.inspectionCycleName= data[0].inspectionCycleName;
          // this.equipInfo.inspectionCycleCd= data[0].inspectionCycleCd;
          // this.equipInfo.recentInspectionDate= data[0].recentInspectionDate;
          this.equipInfo.relatedLaws= data[0].relatedLaws;
      }
    },
    remove() {
      if (this.equipInfo.equipmentCd) {
        this.equipInfo.equipmentCd = '';
        this.equipInfo.equipmentNo = '';
        this.equipInfo.machineryName = '';
        this.equipInfo.amount = '';
        this.equipInfo.departmentDeptCd = '';
        this.equipInfo.subProcessCd = '';
      }
    },
    saveCallback(equipInfo) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.hhmHazardousMachineryId) {
        this.$emit('changeStatus')
      }
        this.$emit('saveCallback', equipInfo.data)
      this.$set(this.attachInfo, 'taskKey', equipInfo.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
    },
    removeHazard() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.hhmHazardousMachineryId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange() {
      if (!this.equipInfo.inspectionSubjectFlag || this.equipInfo.inspectionSubjectFlag === 'N') {
        this.equipInfo.inspectionKindCd = null;
        this.equipInfo.inspectionCycle = null;
      } 
    },
  }
};
</script>